import { Modal, useMediaQuery, useTheme } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"
import { t } from "i18next"
import React, { useEffect, useRef, useState } from "react"
import "./recentActivities.scss"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"

import { EffectCoverflow, Navigation, Pagination } from "swiper"

const AboutUsRecentActivities = () => {
  const sliderRef = useRef(null)
  const isMobileSmall = useMediaQuery(useTheme().breakpoints.down("sm"))
  const [openImgView, setOpenImgView] = useState(false)
  const [imgDataToView, setImgDataToView] = useState("")

  function handleImgViewClick(isOpen, imgData) {
    setImgDataToView(imgData)
    setOpenImgView(isOpen)
  }

  return (
    <div className="recent-activities">
      <div className="title-desc">
        <div className="title">{t("about-us.our-recent-activities")}</div>
        <div className="desc">
          {t("about-us.our-recent-activities-description")}
        </div>
      </div>
      {isMobileSmall ? (
        <Swiper
          onBeforeInit={swiper => {
            sliderRef.current = swiper
          }}
          slidesPerView={1}
          coverflowEffect={{
            rotate: 0,
            stretch: -42,
            scale: 1,
            depth: 0,
            modifier: 1.0,
            slideShadows: false,
          }}
          centeredSlides
          modules={[EffectCoverflow, Pagination]}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            type: "bullets",
          }}
          scrollbar={{ draggable: true }}
          className="mobile-img-slider"
        >
          {masonryData.flat().map((e, i) => (
            <SwiperSlide key={i}>
              {e.isVideo ? (
                <GalleryVideo
                  image={masonryData
                    .flat()
                    .filter(data => data.isVideo)
                    .findIndex(vidData => vidData.src === e.src)}
                  video={e.src}
                  isMobileSmall={true}
                />
              ) : (
                <div
                  className="img-container-mobile"
                  key={i}
                  onClick={() => {
                    handleImgViewClick(true, e.src)
                  }}
                >
                  <img src={e.src} />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="activities">
          <div
            className="swipper-pagination-button"
            onClick={() => sliderRef.current?.slidePrev()}
          >
            <StaticImage
              src="../../assets/images/icons/arrow-gallery-left.png"
              className="nav-icon"
              alt=""
            />
          </div>

          <Swiper
            onBeforeInit={swiper => {
              sliderRef.current = swiper
            }}
            slidesPerView={1}
            coverflowEffect={{
              rotate: 0,
              stretch: -42,
              scale: 1,
              depth: 0,
              modifier: 1.0,
              slideShadows: false,
            }}
            centeredSlides
            modules={[EffectCoverflow, Navigation]}
            pagination={{
              clickable: true,
            }}
            scrollbar={{ draggable: true }}
          >
            {masonryData.map((el, idx) => (
              <SwiperSlide key={idx}>
                <div className="masonry">
                  {el.map((e, i) => {
                    return e.isVideo ? (
                      <GalleryVideo
                        image={masonryData
                          .flat()
                          .filter(data => data.isVideo)
                          .findIndex(vidData => vidData.src === e.src)}
                        video={e.src}
                      />
                    ) : (
                      <div
                        className="img-container"
                        key={i}
                        style={{ backgroundImage: `url(${e.src})` }}
                        onClick={() => {
                          handleImgViewClick(true, e.src)
                        }}
                      />
                    )
                  })}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className="swipper-pagination-button"
            onClick={() => sliderRef.current?.slideNext()}
          >
            <StaticImage
              src="../../assets/images/icons/arrow-gallery-right.png"
              className="nav-icon"
              alt=""
            />
          </div>
        </div>
      )}

      <Modal
        open={openImgView}
        onClose={() => {
          handleImgViewClick(false, "")
        }}
        className="img-modal"
      >
        <div className="container">
          <img src={imgDataToView} />
          <div
            className="button-close"
            onClick={() => {
              handleImgViewClick(false, "")
            }}
          >
            {t("general.close")}
          </div>
        </div>
      </Modal>
    </div>
  )
}

function GalleryVideo({ image, video, isMobileSmall }) {
  const [openVideo, setOpenVideo] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const handleOpenVideo = () => {
    setOpenVideo(true)
  }

  const handleCloseVideo = () => {
    setOpenVideo(false)
  }

  return (
    <div className={`gallery${isMobileSmall ? "-mobile" : ""}`}>
      <div className="play-button">
        <div
          type="button"
          onClick={handleOpenVideo}
          onKeyDown={handleOpenVideo}
          aria-hidden="true"
          onMouseOver={() => {
            setIsHover(true)
          }}
          onFocus={() => {
            setIsHover(true)
          }}
          onMouseOut={() => {
            setIsHover(false)
          }}
          onBlur={() => {
            setIsHover(false)
          }}
        >
          {isHover ? (
            <StaticImage
              src="../../assets/images/icons/play_1.png"
              className="play-icon"
              alt="button"
            />
          ) : (
            <StaticImage
              src="../../assets/images/icons/play_2.png"
              className="play-icon"
              alt="button"
            />
          )}
        </div>
      </div>

      {image === 0 && (
        <StaticImage
          src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/SiGZLMu_-Ov3WOHpQfOzGucXZ63jrVOqlxPUSOTQkb4.jpg"
          className="gallery-img"
          alt=""
        />
      )}
      {image === 1 && (
        <StaticImage
          src="../../assets/images/picture/about/gallery/cover-tim-it.png"
          className="gallery-img"
          alt=""
        />
      )}
      {image === 2 && (
        <StaticImage
          src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/10_CoverVideoeyechecking.png"
          className="gallery-img"
          alt=""
        />
      )}
      {image === 3 && (
        <StaticImage
          src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/8_CoverVideoBody%20Combat.png"
          className="gallery-img"
          alt=""
        />
      )}
      {image === 4 && (
        <StaticImage
          src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/9_CoverVideoAkulakuMedan.png"
          className="gallery-img"
          alt=""
        />
      )}

      {image === 5 && (
        <StaticImage
          src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/7_CoverVideoAntigen.png"
          className="gallery-img"
          alt=""
        />
      )}

      {image === 6 && (
        <StaticImage
          src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/5_CoverVideo17an2.png"
          className="gallery-img"
          alt=""
        />
      )}

      {/* <img src={image} className="gallery-img" alt="" /> */}
      <Modal open={openVideo} onClose={handleCloseVideo}>
        <div className="video-modal">
          <video
            src={video}
            autoPlay={true}
            className="video"
            controls={true}
          ></video>
        </div>
      </Modal>
    </div>
  )
}

const masonryData = [
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/sRU-Wg6UBBI6_ZFfMDe0i5BTXXJr0fC-nGcbo64qyTU.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/VlfIGyswmCcgSNYB0jYmSClL1P9svgOU534o3xMBf2g.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/5-93t9Ljfdru-UrXUt-pywA_K6seo_ERWEDCEMCAQ5E.mp4",
      isVideo: true,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/bPBy7ySyY1OAiczH3ELicFEoxIi1CYaJdjnZ2841HCI.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/ufeV5CnL6dBihMILLn7KeHzNCdVeww_ww5Y0vKFl6iM.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/tDwtUjnl4f4ofSTwISXpMbk4bdvPidboP6nK4C1prkw.jpg",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/gWxJLlInjA3VHFn7XH8aG0VYYAyGTp374SxrokMSvSo.jpg",
      isVideo: false,
    },
  ],
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/UyR_6h4N_roUaC1U3y_i5KN7c0qhHZHsFObb6CyldG8.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/1_FintechDayMakasar1.png",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/2_Bukber1.png",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/2_Bukber2.png",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/4_Team%20IT.mp4",
      isVideo: true,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/3_OJK%20Yogya1.png",
      isVideo: false,
    },
  ],
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/5_17an1.png",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/10_eye%20checking.mp4",
      isVideo: true,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/5_17an3.png",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/6_Yoga2.png",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/1_FintechDayMakasar2.png",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/wyy5DZty9l7Vn6_5S65GnwkrfmPSpif3b_S7naVqk1g.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/coTJgFI6vsrLxwxl9LJP3kmdC0992LRh6eQjnPbyqEs.jpg",
      isVideo: false,
    },
  ],
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/8_Body%20Combat%20.mp4",
      isVideo: true,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/dW-LbtmcRM49TdPsGjeBOeKPyRfg_mNbWNc_MDmv_uA.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/AujKYLi4FWUAHlKve77QUS76EjOnRB8wUWY7CNyXo8U.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/yKiBYgQ9cXqKIjt2dGUXjdGQyVLM0Qt-JxrLMH4dEa0.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/HEMKVb05Uj5WncYdTAfpuwGHgxyiPtYylyQ995lbcxw.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/IuUqpJIZ0vpA4sgNTGERbCeCmiKgIoPqJyyg0LLt0ss.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/Me1aV4PojGq4qmZG0R5yrn89PGZoTeuklhCccZHPnZg.jpg",
      isVideo: false,
    },
  ],
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/9_Akulaku%20Medan.mp4",
      isVideo: true,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/5dPKNlxhbmWYtNrPkURatVlnP4xO-3Jk-S0Q7lawKLo.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/0JAH7QeYXMe_P5KLeTPb-0ErFc7zjanF12BYo1uIjqc.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/TC76dVzRQiykXOhz4hA7YNeytVN89RWRlUkB2gV44OQ.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/-19FP23qEWit8PcYU7Y9VZG-nkko_5ENted8w-TGbd0.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/jvPQLk9wmxPvYttQICXwX4WFkVc0tcuwgIs54Qj8Ylg.jpg",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/yS_iWnU81zJbPxWYnmOO8pppEeAjlpg4UyWMGw60pBk.jpg",
      isVideo: false,
    },
  ],
]

export default AboutUsRecentActivities
