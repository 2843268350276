import React, { useState } from "react"
import "./TableCollapse.css"
import AddCircle from "@material-ui/icons/AddCircle"
import RemoveCircle from "@material-ui/icons/RemoveCircle"
import { useTranslation } from "react-i18next"
import { withTrans } from "../../i18n/withTrans"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"

function TableCollapse() {
  const { t } = useTranslation()

  const [open2018, setOpen2018] = useState(false)
  const [open2019, setOpen2019] = useState(false)
  const [open2020, setOpen2020] = useState(false)

  const checkMore2018 = e => {
    setOpen2018(!open2018)
  }
  const checkMore2019 = e => {
    setOpen2019(!open2019)
  }
  const checkMore2020 = e => {
    setOpen2020(!open2020)
  }

  return (
    <div className="events">
      <div className="scroll">
        <dl>
          <dt className="scroll-tile">
            <li style={{ display: "flex", justifyContent: "center" }}>
              {t(`about-us.year`)}
            </li>
            <li style={{ display: "flex", justifyContent: "center" }}>
              {t(`about-us.month`)}
            </li>
            <li style={{ display: "flex", justifyContent: "center" }}>
              {t(`about-us.date`)}
            </li>
            <li style={{ display: "flex", justifyContent: "center" }}>
              {t(`about-us.event-name`)}
            </li>
            <li style={{ display: "flex", justifyContent: "center" }}>
              {t(`about-us.location`)}
            </li>
            <li style={{ display: "flex", justifyContent: "center" }}>
              {t(`about-us.partnership`)}
            </li>
          </dt>
          <dd>
            <ol>
              <div>
                <span
                  aria-hidden="true"
                  className="more"
                  onClick={e => {
                    checkMore2018(e)
                  }}
                  onKeyDown={e => {
                    checkMore2018(e)
                  }}
                >
                  {open2018 ? (
                    <KeyboardArrowUp
                      style={{
                        color: "white",
                        marginRight: "5px",
                        background: "#000000",
                        borderRadius: "50%",
                      }}
                      onMouseOver={e => (e.currentTarget.style.color = "black")}
                      onMouseOut={e =>
                        (e.currentTarget.style.color = "#f28e1e")
                      }
                    />
                  ) : (
                    <KeyboardArrowDown
                      style={{
                        color: "white",
                        marginRight: "5px",
                        background: "#000000",
                        borderRadius: "50%",
                      }}
                      onMouseOver={e =>
                        (e.currentTarget.style.color = "#f28e1e")
                      }
                      onMouseOut={e => (e.currentTarget.style.color = "black")}
                    />
                  )}
                </span>
                2018
              </div>
              <li>August</li>
              <li>11 August</li>
              <li>
                Lenders Gathering Asetku Explore the World of Digital Financial
                Service
              </li>
              <li>Bengawan Solo, Jakarta</li>
              <li>Non</li>
            </ol>
            <div className={open2018 ? "show" : "hide"}>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>20-25 August</li>
                <li>Focus Group Discussion (FGD) with Lenders Surabaya</li>
                <li></li>
                <li>Non</li>
              </ul>
            </div>
          </dd>

          <div className={open2018 ? "show" : "hide"}>
            <dd>
              <ol>
                <li></li>
                <li>September</li>
                <li>10-16 September</li>
                <li>Focus Group Discussion (FGD) with Lenders Medan</li>
                <li></li>
                <li>Non</li>
              </ol>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>10-15 September</li>
                <li>Focus Group Discussion (FGD) with Lenders Medan</li>
                <li></li>
                <li>Non</li>
              </ul>
            </dd>

            <dd>
              <ol>
                <li></li>
                <li>December</li>
                <li>15 December</li>
                <li>Challenges & Opportunities in Fintech P2P Lending</li>
                <li>Surabaya</li>
                <li>Non</li>
              </ol>
            </dd>
          </div>

          <dd>
            <ol>
              <div>
                <span
                  aria-hidden="true"
                  className="more"
                  onClick={e => {
                    checkMore2019(e)
                  }}
                  onKeyDown={e => {
                    checkMore2019(e)
                  }}
                >
                  {open2019 ? (
                    <KeyboardArrowUp
                      style={{
                        color: "white",
                        marginRight: "5px",
                        background: "#000000",
                        borderRadius: "50%",
                      }}
                      onMouseOver={e => (e.currentTarget.style.color = "black")}
                      onMouseOut={e =>
                        (e.currentTarget.style.color = "#f28e1e")
                      }
                    />
                  ) : (
                    <KeyboardArrowDown
                      style={{
                        color: "white",
                        marginRight: "5px",
                        background: "#000000",
                        borderRadius: "50%",
                      }}
                      onMouseOver={e =>
                        (e.currentTarget.style.color = "#f28e1e")
                      }
                      onMouseOut={e => (e.currentTarget.style.color = "black")}
                    />
                  )}
                </span>
                2019
              </div>
              <li>January</li>
              <li>30 January</li>
              <li>Konferensi Akulaku Sahabatku</li>
              <li>Patio Jakarta</li>
              <li>Akulaku</li>
            </ol>
          </dd>

          <div className={open2019 ? "show" : "hide"}>
            <dd>
              <ol className="bg-grey">
                <li></li>
                <li>February</li>
                <li>11 February</li>
                <li>Konferensi Pers Asetku #JelasBerkembang</li>
                <li>Ritz Carlton Pasific Place, Jakarta</li>
                <li>Non</li>
              </ol>
              <ul>
                <li></li>
                <li></li>
                <li>20 February</li>
                <li>Fintech Talk Vol.2 By Aftech</li>
                <li>Satrio Tower, Jakarta</li>
                <li>Aftech</li>
              </ul>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>23 February</li>
                <li>
                  Gerakan Peningkatan Inklusi Keuangan Indonesia Bersama Asetku
                </li>
                <li>JW Marriott, Medan</li>
                <li>Non</li>
              </ul>
              <ul>
                <li></li>
                <li></li>
                <li>27 February</li>
                <li>Challenges and Opportunities in Fintech P2P Lending</li>
                <li>Binus University International, Jakarta</li>
                <li>Non</li>
              </ul>
            </dd>

            <dd>
              <ol className="bg-grey">
                <li></li>
                <li>March</li>
                <li>8 March</li>
                <li>
                  Accounting in Revolution 4.0 to Achieve SDGs 2030 at
                  Accounting Fair 2019
                </li>
                <li>Universitas Bakrie, Jakarta</li>
                <li>Non</li>
              </ol>
              <ul>
                <li></li>
                <li></li>
                <li>8 March</li>
                <li>Stronger HIPMI 4.0 Powered by Asetku</li>
                <li>Marbella Hotel, Bandung</li>
                <li>HIPMI</li>
              </ul>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>16-17 March</li>
                <li>Sosialisasi Fintech Asetku #JelasBerkembang</li>
                <li>Bintaro X Change Tangerang - Akulaku KarnavAL</li>
                <li>Akulaku</li>
              </ul>
              <ul>
                <li></li>
                <li></li>
                <li>30 March</li>
                <li>
                  How to Reach Your Financial Goal Faster in Digital Era bersama
                  Finansialku
                </li>
                <li>Warunk Upnormal Tebet, Jakarta</li>
                <li>Finansialku</li>
              </ul>
            </dd>
            <dd>
              <ol className="bg-grey">
                <li></li>
                <li>April</li>
                <li>10 April</li>
                <li>Jadi Millennial Cerdas di Era Digital Finance</li>
                <li>
                  Gd. Aula Pasca Sarjana Universitas Diponegoro Jl. Hayam Wuruk
                  Semarang
                </li>
                <li>Non</li>
              </ol>
              <ul>
                <li></li>
                <li></li>
                <li>13 April</li>
                <li>The Rise of Fintech</li>
                <li>Atmajaya University</li>
                <li>Non</li>
              </ul>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>25 April</li>
                <li>Powering Indonesia SME's with Technology</li>
                <li>The Kasablanka - Main Hall. Jakarta</li>
                <li>Mekari</li>
              </ul>
            </dd>
            <dd>
              <ol>
                <li></li>
                <li>May</li>
                <li>2-3 May</li>
                <li>Fintech Days 2019</li>
                <li>Novotel Hotel Palembang</li>
                <li>AFPI</li>
              </ol>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>8-11 May</li>
                <li>Ramadan Bersama Akulaku #AkuBisaBerbagi</li>
                <li>Senayan City, Jakarta</li>
                <li>Akulaku</li>
              </ul>
              <ul>
                <li></li>
                <li></li>
                <li>24-26 May</li>
                <li>ICS (Indonesian Cashless Society) 2019</li>
                <li>Summarecon Mall Bekasi</li>
                <li>Non</li>
              </ul>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>25 May</li>
                <li>Instagram 3.0 - Zero To Millions </li>
                <li>Hotel Ibis Style Gajah Mada</li>
                <li>Rintisan</li>
              </ul>
            </dd>
            <dd>
              <ol>
                <li></li>
                <li>June</li>
                <li>25 June</li>
                <li>CGS-CIMB 13th Annual Indonesia Conference</li>
                <li>@ Ayana MidPlaza</li>
                <li>Non</li>
              </ol>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>27 June</li>
                <li>Bagaimana merencanakan keuangan & danai di era digital</li>
                <li>@ C3 Jogjakarta Community</li>
                <li>Finansialku</li>
              </ul>
            </dd>
            <dd>
              <ol>
                <li></li>
                <li>July</li>
                <li>19 July</li>
                <li>Jadi Pengguna Cerdas di Era Digital Finance</li>
                <li>@ DILo Coworking Space Padang</li>
                <li>Non</li>
              </ol>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>26 July</li>
                <li>Jadi Pengguna Cerdas di Era Digital Finance</li>
                <li>@ Meet Up Coworking Space Pekanbaru</li>
                <li>Non</li>
              </ul>
            </dd>

            <dd>
              <ol>
                <li></li>
                <li>August</li>
                <li>2 August</li>
                <li>Fintech Fest 2019</li>
                <li>@ Four Points Manado</li>
                <li>AFPI</li>
              </ol>
            </dd>

            <dd>
              <ol className="bg-grey">
                <li></li>
                <li>September</li>
                <li>3-5 September</li>
                <li>Fintech Exhibition Fintech Day OJK Seminar</li>
                <li>
                  @BigMall Samarinda @Aston Samarinda Hotel & Convention Center
                </li>
                <li>AFPI & OJK</li>
              </ol>
              <ul>
                <li></li>
                <li></li>
                <li>8 September</li>
                <li>Millenials on Industrial Revolution 4.0</li>
                <li>@ G2 Fakultas Ekonomi Universitas Negeri Surabaya</li>
                <li>Universitas Negeri Surabaya</li>
              </ul>
              <ul className="bg-grey">
                <li></li>
                <li></li>
                <li>23-24 September</li>
                <li>Indonesia Fintech Summit & Expo 2019</li>
                <li>@ Jakarta Convention Center</li>
                <li>Aftech</li>
              </ul>
            </dd>

            <dd>
              <ol>
                <li></li>
                <li>October</li>
                <li>17-20 October</li>
                <li>Finexpo & Sundown Run 2019</li>
                <li>@ Kota Kasablanka</li>
                <li>OJK</li>
              </ol>
            </dd>

            <dd>
              <ol className="bg-grey">
                <li></li>
                <li>November</li>
                <li>6-8 November</li>
                <li>Indonesia Fintech Show 2019</li>
                <li>@ Jakarta Convention Center</li>
                <li>Tarsus</li>
              </ol>
              <ul>
                <li></li>
                <li></li>
                <li>29 November 2019 - 1 Desember 2019</li>
                <li>Fintech Exhibition Surabaya 219</li>
                <li>@Convension Hall, Pakuwon Trade Center</li>
                <li>AFPI</li>
              </ul>
            </dd>
          </div>

          {/* 2020_____________________________ */}

          <dd>
            <ol>
              <div>
                <span
                  aria-hidden="true"
                  className="more"
                  onClick={e => {
                    checkMore2020(e)
                  }}
                  onKeyDown={e => {
                    checkMore2020(e)
                  }}
                >
                  {open2020 ? (
                    <KeyboardArrowUp
                      style={{
                        color: "white",
                        marginRight: "5px",
                        background: "#000000",
                        borderRadius: "50%",
                      }}
                      onMouseOver={e => (e.currentTarget.style.color = "black")}
                      onMouseOut={e =>
                        (e.currentTarget.style.color = "#f28e1e")
                      }
                    />
                  ) : (
                    <KeyboardArrowDown
                      style={{
                        color: "white",
                        marginRight: "5px",
                        background: "#000000",
                        borderRadius: "50%",
                      }}
                      onMouseOver={e =>
                        (e.currentTarget.style.color = "#f28e1e")
                      }
                      onMouseOut={e => (e.currentTarget.style.color = "black")}
                    />
                  )}
                </span>
                2020
              </div>
              <li>March</li>
              <li>March 10</li>
              <li>AFPI Goes to Campus</li>
              <li>Universitas Gunung Jati, Cirebon</li>
              <li>AFPI</li>
            </ol>
          </dd>

          <div className={open2020 ? "show" : "hide"}>
            <dd>
              <ol className="bg-grey">
                <li></li>
                <li>June</li>
                <li>June 16</li>
                <li>AFPI Goes to Campus</li>
                <li>Universitas Jambi, Jambi</li>
                <li>AFPI</li>
              </ol>
            </dd>

            <dd>
              <ol>
                <li></li>
                <li>July</li>
                <li>July 9</li>
                <li>AFPI Goes to Campus</li>
                <li>Universitas Tanjungpura, Pontianak</li>
                <li>AFPI</li>
              </ol>
            </dd>

            <dd>
              <ol className="bg-grey">
                <li></li>
                <li>August</li>
                <li>August 11</li>
                <li>AFPI Goes to Campus</li>
                <li>Universitas Bengkulu, Bengkulu</li>
                <li>AFPI</li>
              </ol>
              <ul>
                <li></li>
                <li></li>
                <li>August 25</li>
                <li>AFPI Goes to Campus</li>
                <li>Universitas Halu Oleo, Kendari</li>
                <li>AFPI</li>
              </ul>
            </dd>

            <dd>
              <ol className="bg-grey">
                <li></li>
                <li>October</li>
                <li>October 6</li>
                <li>AFPI Goes to Campus</li>
                <li>Universitas Mataram, Mataram</li>
                <li>AFPI</li>
              </ol>
            </dd>

            <dd>
              <ol>
                <li></li>
                <li>November</li>
                <li>November 17</li>
                <li>
                  Indonesian Fintech Facing Recession: Brutal Challange or Great
                  Opportunity?
                </li>
                <li>Live via Youtube DailySocial</li>
                <li>DailySocial</li>
              </ol>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default withTrans(TableCollapse)
